<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <a-tabs v-model="activeKey" @tabClick="changeTab">
        <a-tab-pane :key="1" tab="可视化"/>
        <a-tab-pane :key="2" tab="常规报表"/>
      </a-tabs>
      <a-card :bordered="false" v-show="activeKey===1">
        <a-row>
          <a-col :span="8">
            <div style="padding: 2px">
              <div class="one" style="float: left;" >
                <span :class="isSexSelect === '1'?'isActive': ''" @click="handelSexClick('1')" style="font-size: 12px">昨日</span>
                <span :class="isSexSelect === '7'?'isActive': ''" @click="handelSexClick('7')" style="font-size: 12px">近7日</span>
                <span :class="isSexSelect === '30'?'isActive': ''" @click="handelSexClick('30')" style="margin-right:10px; font-size: 12px">近30日</span>
              </div>
              <a-range-picker style="width: 300px" valueFormat="YYYY-MM-DD"  @change="sexDateChange"  size="small"/>
            </div>
            <div id="sexChart" style="width: 600px;height: 400px"></div>
          </a-col>
          <a-col :span="8">
            <div style="padding: 5px">
              <div class="one"  style="float: left;">
                <span :class="isAgeSelect === '1'?'isActive': ''" @click="handelAgeClick('1')" style="font-size: 12px">昨日</span>
                <span :class="isAgeSelect === '7'?'isActive': ''" @click="handelAgeClick('7')" style="font-size: 12px">近7日</span>
                <span :class="isAgeSelect === '30'?'isActive': ''" @click="handelAgeClick('30')" style="font-size: 12px;margin-right:10px;">近30日</span>
              </div>
              <a-range-picker  style="width: 300px" valueFormat="YYYY-MM-DD"  @change="ageDateChange"  size="small"/>
            </div>
            <div id="ageChart" style="width: 600px;height: 400px"></div>
          </a-col>
          <a-col :span="8">
            <div style="padding: 2px">
              <div class="one" style="float: left;" >
                <span :class="isInviteSelect === '1'?'isActive': ''" @click="handelInviteClick('1')" style="font-size: 12px">昨日</span>
                <span :class="isInviteSelect === '7'?'isActive': ''" @click="handelInviteClick('7')" style="font-size: 12px">近7日</span>
                <span :class="isInviteSelect === '30'?'isActive': ''" @click="handelInviteClick('30')" style="margin-right:10px; font-size: 12px">近30日</span>
              </div>
              <a-range-picker style="width: 300px" valueFormat="YYYY-MM-DD"  @change="inviteDateChange"  size="small"/>
            </div>
            <div id="inviteChart" style="width: 600px;height: 400px"></div>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <div style="padding: 2px">
              <div class="one" style="float: left;" >
                <span :class="isAgeOrderSelect === '1'?'isActive': ''" @click="handelAgeOrderClick('1')" style="font-size: 12px">昨日</span>
                <span :class="isAgeOrderSelect === '7'?'isActive': ''" @click="handelAgeOrderClick('7')" style="font-size: 12px">近7日</span>
                <span :class="isAgeOrderSelect === '30'?'isActive': ''" @click="handelAgeOrderClick('30')" style="margin-right:10px; font-size: 12px">近30日</span>
              </div>
              <a-range-picker style="width: 300px" valueFormat="YYYY-MM-DD"  @change="ageOrderDateChange"  size="small"/>
            </div>
            <div id="ageOrderChart" style="width: 600px;height: 400px"></div>
          </a-col>
          <a-col :span="8">
            <div style="padding: 2px">
              <div class="one" style="float: left;" >
                <span :class="isSexOrderSelect === '1'?'isActive': ''" @click="handelSexOrderClick('1')" style="font-size: 12px">昨日</span>
                <span :class="isSexOrderSelect === '7'?'isActive': ''" @click="handelSexOrderClick('7')" style="font-size: 12px">近7日</span>
                <span :class="isSexOrderSelect === '30'?'isActive': ''" @click="handelSexOrderClick('30')" style="margin-right:10px; font-size: 12px">近30日</span>
              </div>
              <a-range-picker style="width: 300px" valueFormat="YYYY-MM-DD"  @change="sexOrderDateChange"  size="small"/>
            </div>
            <div id="sexOrderChart" style="width: 600px;height: 400px"></div>
          </a-col>

        </a-row>
        <a-row>
          <a-col :span="18">
            <div style="padding: 5px">
              <div class="one"  style="float: left;">
                <span :class="isOrderAmountSelect === '1'?'isActive': ''" @click="handelOrderAmountClick('1')">昨日</span>
                <span :class="isOrderAmountSelect === '7'?'isActive': ''" @click="handelOrderAmountClick('7')">近7日</span>
                <span :class="isOrderAmountSelect === '30'?'isActive': ''" @click="handelOrderAmountClick('30')" style="margin-right:10px;">近30日</span>
              </div>
              <a-range-picker  valueFormat="YYYY-MM-DD"  @change="orderAmountDateChange"  size="default"/>
            </div>
            <div id="orderAmountChart" style="height: 600px"></div>
          </a-col>
        </a-row>
      </a-card>
      <a-card :bordered="false" v-show="activeKey===2">
        <div>
          <!-- 条件搜索 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="48">
                <a-col :md="6" :sm="24">
                  <a-form-item :label="$t('用户.手机号')" prop="mobile">
                    <a-input v-model="queryParam.mobile" @pressEnter="handleQuery" :maxLength="20"  :placeholder="$t('通用.输入.请输入')+$t('用户.手机号')" allow-clear/>
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="24">
                  <a-form-item :label="$t('用户.邮箱')" prop="email">
                    <a-input v-model="queryParam.email" @pressEnter="handleQuery" :maxLength="30" :placeholder="$t('通用.输入.请输入')+$t('用户.邮箱')" allow-clear/>
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="24">
                  <a-form-item :label="$t('用户.昵称')" prop="nickName">
                    <a-input v-model="queryParam.nickName" @pressEnter="handleQuery" :maxLength="20" :placeholder="$t('通用.输入.请输入')+$t('用户.昵称')" allow-clear/>
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="24">
                  <a-form-item :label="$t('用户.性别')" prop="sex">
                    <a-select :placeholder="$t('通用.输入.请选择')+$t('用户.性别')" style="width: 100%" v-model="queryParam.sex" allowClear>
                      <a-select-option v-for="(item, index) in customDict.SexEnum" :value="item.type" :key="index">
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="24">
                  <a-form-item :label="$t('报表.用户.注册开始时间')" prop="startTime">
                    <a-date-picker style="width: 100%" v-model="queryParam.startTime"
                                   :show-time="{defaultValue: moment('00:00:00', 'HH:mm:ss')}"
                                   valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="24">
                  <a-form-item :label="$t('报表.用户.注册结束时间')" prop="endTime">
                    <a-date-picker style="width: 100%" v-model="queryParam.endTime"
                                   :show-time="{defaultValue: moment('23:59:59', 'HH:mm:ss')}"
                                   valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
                  </a-form-item>
                </a-col>

<!--                <a-col :md="6" :sm="24">
                  <a-form-item :label="$t('报表.商品.订单开始时间')" prop="orderStartTime">
                    <a-date-picker style="width: 100%" v-model="queryParam.orderStartTime"
                                   :show-time="{defaultValue: moment('00:00:00', 'HH:mm:ss')}"
                                   valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="24">
                  <a-form-item :label="$t('报表.商品.订单结束时间')" prop="orderEndTime">
                    <a-date-picker style="width: 100%" v-model="queryParam.orderEndTime"
                                   :show-time="{defaultValue: moment('23:59:59', 'HH:mm:ss')}"
                                   valueFormat="YYYY-MM-DD HH:mm:ss" allow-clear/>
                  </a-form-item>
                </a-col>-->

                <a-col :md="6" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{$t('通用.按钮.查询')}}</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />{{$t('通用.按钮.重置')}}</a-button>
              </span>
                </a-col>
              </a-row>
            </a-form>
            <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
              <img alt="example" style="width: 100%" :src="previewUrl" />
            </a-modal>
          </div>
          <!-- 操作 -->
          <div class="table-operations">
            <a-button type="primary" @click="handleExport" v-hasPermi="['biz:user:export']">
              <a-icon type="download" />{{$t('通用.按钮.导出')}}
            </a-button>
            <a-button type="primary" :disabled="multiple"  @click="handleSelectExport" v-hasPermi="['biz:user:export']">
              <a-icon type="download" />{{$t('通用.按钮.选中导出')}}
            </a-button>
            <table-setting
              :style="{float: 'right'}"
              :table-size.sync="tableSize"
              v-model="columns"
              :refresh-loading="loading"
              @refresh="getList" />
          </div>
          <!-- 数据展示 -->
          <a-table
            class="sta-table"
            :loading="loading"
            size="small"
            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            rowKey="userId"
            @change="handleSortChange"
            :columns="columns"
            :data-source="list"
            :pagination="false"
            :bordered="tableBordered"
          >

         <span slot="sex" slot-scope="text, record">
           <custom-dict-tag :options="customDict.SexEnum" :value="record.sex"/>
          </span>
            <span slot="levelId" slot-scope="text, record">
            {{ record.levelName }}
          </span>
            <span slot="orderCount" slot-scope="text, record">
            <a @click="handleJumpOrder(record.userId)">{{record.orderCount}}</a>
          </span>
            <span slot="score" slot-scope="text, record">
            <a @click="handleJumpScore(record.userId)">{{record.score}}</a>
          </span>
            <template slot="avatar" slot-scope="text, record">
              <div>
                <img v-if="record.avatar" :src="record.avatar"  style="width:60px;height:62px;" @click="handleRecordPreview(record.avatar)" alt="none"/>
              </div>
            </template>
            <span slot="createTime" slot-scope="text, record">
            {{ parseTime(record.createTime) }}
          </span>
          </a-table>
          <!-- 分页 -->
          <a-pagination
            class="ant-table-pagination"
            show-size-changer
            show-quick-jumper
            :pageSizeOptions="pageSizeOptions"
            :current="queryParam.pageNum"
            :total="total"
            :page-size="queryParam.pageSize"
            :showTotal="total => getTotal(total)"
            @showSizeChange="onShowSizeChange"
            @change="changeSize"
          />
        </div>
      </a-card>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import * as echarts from 'echarts'
import {userSex, userAge, userSexOrder, userAgeOrder, userInvite, userSta} from "@/api/statistics/user";
import moment from 'moment';
import CustomDictTag from "@/components/DictCustomTag";
import {mapGetters} from 'vuex'
import {tableMixin} from '@/store/table-mixin'

export default {
  name: 'UserStatistics',
  components: {
    CustomDictTag
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      categoryList: [{id: "0", categoryName: ''}],
      cateOptions: [],
      brandList: [],
      labelList: [],
      pageSizeOptions: ['10', '20', '50', '100'],
      filteredInfo: {},
      sortedInfo: {},
      selectedRowKeys: [],
      selectedRows: [],
      //预览封面
      previewImageVisible: false,
      previewUrl: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        mobile: undefined,
        email: undefined,
        nickName: undefined,
        sex: undefined,
        invitationCode: undefined,
        isNotice: undefined,
        status: undefined,
        startTime: undefined,
        endTime: undefined,
        orderStartTime: undefined,
        orderEndTime: undefined,
        sortField :'userId',
        sortOrder: 'desc',
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: this.$t('用户.手机号'),
          dataIndex: 'mobile',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          align: 'center'
        },
        // {
        //   title: this.$t('用户.邮箱'),
        //   sorter: 1,
        //   sortDirections: ['descend', 'ascend'],
        //   dataIndex: 'email',
        //   align: 'center'
        // },
        {
          title: this.$t('用户.昵称'),
          dataIndex: 'nickName',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          align: 'center'
        },
        // {
        //   title: this.$t('用户.真实姓名'),
        //   dataIndex: 'realName',
        //   sorter: 1,
        //   sortDirections: ['descend', 'ascend'],
        //   align: 'center'
        // },
        {
          title: this.$t('用户.微信'),
          dataIndex: 'wechat',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          align: 'center'
        },
        /*{
          title: "qq",
          dataIndex: 'qq',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          align: 'center'
        },
        {
          title: this.$t('用户.用户等级'),
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          scopedSlots: { customRender: 'levelId' },
          dataIndex: 'levelId',
          align: 'center'
        },*/
        {
          title: this.$t('用户.性别'),
          dataIndex: 'sex',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          scopedSlots: { customRender: 'sex' },
          align: 'center'
        },
        {
          title:  this.$t('用户.生日'),
          dataIndex: 'birthday',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          align: 'center'
        },
        {
          title: this.$t('用户.注册时间'),
          dataIndex: 'userId',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          scopedSlots: { customRender: 'createTime' },
          align: 'center'
        },
        {
          title: this.$t('用户.积分'),
          dataIndex: 'score',
          scopedSlots: { customRender: 'score' },
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          align: 'center'
        },
        {
          title: this.$t('报表.商品.订单量'),
          dataIndex: 'orderCount',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          scopedSlots: { customRender: 'orderCount' },
          align: 'center'
        },
        {
          title: this.$t('报表.用户.购买商品量'),
          dataIndex: 'goodsCount',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          align: 'center'
        },
        {
          title: this.$t('报表.用户.支付金额'),
          dataIndex: 'payAmount',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          align: 'center'
        },
        {
          title: this.$t('报表.用户.最后下单时间'),
          dataIndex: 'lastOrderTime',
          sorter: 1,
          sortDirections: ['descend', 'ascend'],
          align: 'center'
        },
      ],
      activeKey: 1,
      sexParam: {
        chartType: '1',
        startTime: undefined,
        endTime:undefined,
      },
      ageParam: {
        chartType: '1',
        startTime: undefined,
        endTime:undefined,
      },
      sexOrderParam: {
        chartType: '1',
        startTime: undefined,
        endTime:undefined,
      },
      ageOrderParam: {
        chartType: '1',
        startTime: undefined,
        endTime:undefined,
      },
      inviteParam: {
        chartType: '1',
        startTime: undefined,
        endTime:undefined,
      },
      orderAmountParam: {
        chartType: '1',
        startTime: undefined,
        endTime:undefined,
        pageNum:1,
        pageSize:10,
        sortField:'payAmount',
        sortOrder:'desc'
      },
      activeParam: {
        chartType: '1',
        endTime:undefined,
      },
      /*销售数据选择*/
      isSexSelect: undefined,
      isAgeSelect: undefined,
      isInviteSelect: undefined,
      isSexOrderSelect: undefined,
      isOrderAmountSelect: undefined,
      isAgeOrderSelect: undefined,
      isActiveSelect: undefined,

    }
  },
  filters: {},
  created() {
    this.getList()
  },
  mounted() {
    this.drawAgeChart()
    this.drawSexChart()
    this.drawSexOrderChart()
    this.drawAgeOrderChart()
    this.drawInviteChart()
    this.drawOrderAmountChart()
    this.resetQuery();
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  methods: {
    moment,
    getTotal(total) {
      return this.$t('通用.文本.共')+total+this.$t('通用.文本.条')
    },
    /** 查询商品列表 */
    getList () {
      this.loading = true
      userSta(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    handleJumpOrder(buyerId) {
      this.$router.push({
        name: 'Sell',
        params:
          {
            buyerId: buyerId,
            isPay: true
          }
      })
    },
    handleJumpScore(buyerId) {
      this.$router.push({
        name: 'ScoreLog',
        params:
          {
            userId: buyerId,
          }
      })
    },


    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        mobile: undefined,
        email: undefined,
        nickName: undefined,
        sex: undefined,
        invitationCode: undefined,
        isNotice: undefined,
        status: undefined,
        startTime: undefined,
        endTime: undefined,
        orderStartTime: undefined,
        orderEndTime: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    handleSortChange(pagination, filters, sorter) {
      this.filteredInfo = filters
      this.sortedInfo = sorter
      if (sorter.order) {
        /** 排序字段 sortField **/
        /** 排序方式  sortOrder**/
        this.queryParam.sortField = sorter.columnKey
        this.queryParam.sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc'
        this.getList()
      } else {
        this.queryParam.sortField = '';
        this.queryParam.sortOrder = ''
        this.getList()
      }
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.userId)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('通用.文本.此操作将导出当前条件下所有数据而非选中数据'),
        onOk () {
          that.download('statistics/user/user/export', {
            ...that.queryParam
          }, `用户报表_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    },
    handleSelectExport () {
      var that = this
      let queryParam = {"idList": this.ids}
      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('通用.文本.此操作将导出选中数据'),
        onOk () {
          that.download('statistics/user/user/export', queryParam, `用户报表_${new Date().getTime()}.xlsx`)
          that.ids = []
          that.multiple = true;
          that.selectedRowKeys=[]
        },
        onCancel () {
          that.ids = []
          that.multiple = true;
          that.selectedRowKeys=[]
        }
      })
    },

    changeTab(index) {
      if (index == null) {
        index = undefined;
      }
      this.activeKey = index;
    },

    subMonth(num) {
      const newDate = new Date();
      newDate.setMonth(newDate.getMonth() - num);
      return newDate;
    },
    subDay(num) {
      return moment().add(-num, 'days');
    },

    sexDateChange(val) {
      this.sexParam.startTime = val[0] + ' 00:00:00';
      this.sexParam.endTime = val[1] + ' 23:59:59';
      this.drawSexChart();
    },
    ageDateChange(val) {
      this.ageParam.startTime = val[0] + ' 00:00:00';
      this.ageParam.endTime = val[1]+ ' 23:59:59';
      this.drawAgeChart();
    },
    handelSexClick(val) {
      this.isSexSelect = val;
      this.sexParam.chartType = '1';
      this.sexParam.startTime = this.subDay(val).format('YYYY-MM-DD') + " 00:00:00";
      this.sexParam.endTime = moment().format('YYYY-MM-DD') + " 23:59:59";
      this.drawSexChart();
    },
    handelAgeClick(val) {
      this.isAgeSelect = val;
      this.ageParam.chartType = '1';
      this.ageParam.startTime = this.subDay(val).format('YYYY-MM-DD') + " 00:00:00";
      this.ageParam.endTime = moment().format('YYYY-MM-DD') + " 23:59:59";
      this.drawAgeChart();
    },

    sexOrderDateChange(val) {
      this.sexOrderParam.startTime = val[0] + ' 00:00:00';
      this.sexOrderParam.endTime = val[1] + ' 23:59:59';
      this.drawSexOrderChart();
    },
    ageOrderDateChange(val) {
      this.ageOrderParam.startTime = val[0] + ' 00:00:00';
      this.ageOrderParam.endTime = val[1]+ ' 23:59:59';
      this.drawAgeOrderChart();
    },
    inviteDateChange(val) {
      this.inviteParam.startTime = val[0] + ' 00:00:00';
      this.inviteParam.endTime = val[1] + ' 23:59:59';
      this.drawSexOrderChart();
    },
    orderAmountDateChange(val) {
      this.orderAmountParam.startTime = val[0] + ' 00:00:00';
      this.orderAmountParam.endTime = val[1] + ' 23:59:59';
      this.drawOrderAmountChart();
    },
    handelOrderAmountClick(val) {
      this.isOrderAmountSelect = val;
      this.orderAmountParam.chartType = '1';
      this.orderAmountParam.startTime = this.subDay(val).format('YYYY-MM-DD') + " 00:00:00";
      this.orderAmountParam.endTime = moment().format('YYYY-MM-DD') + " 23:59:59";
      this.drawOrderAmountChart();
    },
    handelSexOrderClick(val) {
      this.isSexOrderSelect = val;
      this.sexOrderParam.chartType = '1';
      this.sexOrderParam.startTime = this.subDay(val).format('YYYY-MM-DD') + " 00:00:00";
      this.sexOrderParam.endTime = moment().format('YYYY-MM-DD') + " 23:59:59";
      this.drawSexOrderChart();
    },
    handelAgeOrderClick(val) {
      this.isAgeOrderSelect = val;
      this.ageOrderParam.chartType = '1';
      this.ageOrderParam.startTime = this.subDay(val).format('YYYY-MM-DD') + " 00:00:00";
      this.ageOrderParam.endTime = moment().format('YYYY-MM-DD') + " 23:59:59";
      this.drawAgeOrderChart();
    },
    handelInviteClick(val) {
      this.isInviteSelect = val;
      this.inviteParam.chartType = '1';
      this.inviteParam.startTime = this.subDay(val).format('YYYY-MM-DD') + " 00:00:00";
      this.inviteParam.endTime = moment().format('YYYY-MM-DD') + " 23:59:59";
      this.drawInviteChart();
    },


    drawSexChart() {
      // // 添加
      var chartDom = document.getElementById("sexChart");
      var myChart = echarts.init(chartDom);
      var option;
      userSex(this.sexParam).then((res) => {
        if (res.code === "20001") {
          option = {
            title: {
              text: '性别分布',
              //subtext: 'Fake Data',
              left: 'center'
            },
            tooltip: {
              trigger: 'item'
            },
            legend: {
              orient: 'vertical',
              left: 'left'
            },
            series: [
              {
                name: '性别',
                type: 'pie',
                radius: '50%',
                data: res.data,
                color: ['#a29f9f','#5793f3', '#d14a61'], // 这里设置男性和女性的颜色
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          };
          option && myChart.setOption(option);
        }
      });
    },
    drawAgeChart() {
      // // 添加
      var chartDom = document.getElementById("ageChart");
      var myChart = echarts.init(chartDom);
      var option;
      userAge(this.ageParam).then((res) => {
        if (res.code === "20001") {
          option = {
            title: {
              text: '年龄分布',
              //subtext: 'Fake Data',
              left: 'center'
            },
            tooltip: {
              trigger: 'item'
            },
            legend: {
              orient: 'vertical',
              left: 'left'
            },
            series: [
              {
                name: '年龄',
                type: 'pie',
                radius: '50%',
                data: res.data,
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          };
          option && myChart.setOption(option);
        }
      });
    },

    drawSexOrderChart() {
      // // 添加
      var chartDom = document.getElementById("sexOrderChart");
      var myChart = echarts.init(chartDom);
      var option;
      userSexOrder(this.sexParam).then((res) => {
        if (res.code === "20001") {
          option = {
            title: {
              text: '用户下单性别分布',
              //subtext: 'Fake Data',
              left: 'center'
            },
            tooltip: {
              trigger: 'item'
            },
            legend: {
              orient: 'vertical',
              left: 'left'
            },
            series: [
              {
                name: '性别',
                type: 'pie',
                radius: '50%',
                data: res.data,
                color: ['#a29f9f','#5793f3', '#d14a61'], // 这里设置男性和女性的颜色
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          };
          option && myChart.setOption(option);
        }
      });
    },
    drawAgeOrderChart() {
      // // 添加
      var chartDom = document.getElementById("ageOrderChart");
      var myChart = echarts.init(chartDom);
      var option;
      userAgeOrder(this.ageParam).then((res) => {
        if (res.code === "20001") {
          option = {
            title: {
              text: '用户下单年龄分布',
              //subtext: 'Fake Data',
              left: 'center'
            },
            tooltip: {
              trigger: 'item'
            },
            legend: {
              orient: 'vertical',
              left: 'left'
            },
            series: [
              {
                name: '年龄',
                type: 'pie',
                radius: '50%',
                data: res.data,
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          };
          option && myChart.setOption(option);
        }
      });
    },
    drawInviteChart() {
      // // 添加
      var chartDom = document.getElementById("inviteChart");
      var myChart = echarts.init(chartDom);
      var option;
      userInvite(this.ageParam).then((res) => {
        if (res.code === "20001") {
          option = {
            title: {
              text: '用户渠道分布',
              //subtext: 'Fake Data',
              left: 'center'
            },
            tooltip: {
              trigger: 'item'
            },
            legend: {
              orient: 'vertical',
              left: 'left'
            },
            series: [
              {
                name: '渠道',
                type: 'pie',
                radius: '50%',
                data: res.data,
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          };
          option && myChart.setOption(option);
        }
      });
    },
    drawOrderAmountChart() {
      // // 添加
      var chartDom = document.getElementById("orderAmountChart");
      var myChart = echarts.init(chartDom);
      var option;
      userSta(this.orderAmountParam).then((res) => {
        if (res.code === "20001") {
          let records = res.data.records;
          let name = records.map(e=>e.nickName);
          let payAmount = records.map(e=>e.payAmount);
          option = {
            title: {
              text: '用户消费排行榜',
              //subtext: 'Fake Data',
              left: 'center'
            },
            xAxis: {
              type: 'category',
              data: name
            },
            yAxis: {
              type: 'value',
              axisLabel: { // y轴的标签配置
                show: true, // 显示标签
                formatter: '{value}' // 格式化标签内容，这里直接显示数值
              }
            },
            series: [
              {
                name: '消费金额',
                data: payAmount,
                type: 'bar',
                itemStyle:{
                  emphasis: {
                    label: {
                      show: true,
                      formatter: function (params) {
                        return params.value;
                      },
                      //一下为label的一些配置项，设置外边框，背景颜色，字体颜色等
                      backgroundColor: 'rgba(61,126,255,0.09)',
                      borderColor: '#3d7eff',
                      borderWidth: 0.5,
                      borderRadius: 2,
                      padding: 6,
                      position: 'top',
                      textStyle: {
                        color: '#3D7EFF',
                        fontSize: 14,
                        marginLeft: 0,
                        lineHeight: 20
                      }
                    }
                  }
                }
              }
            ]
          };
          option && myChart.setOption(option);
        }
      });
    },

  }
}
</script>
<style>
.sta-table .ant-table-thead > tr > th {
  background-color: #bae7ff !important;
}
</style>
<style lang="less" scoped>
.one {
  margin-left: 1px;
  margin-right: 1px;

  span {
    display: inline-block;
    line-height: 23px;
    width: 60px;
    text-align: center;
    border-radius: 6px;
    border: 1px solid #999999;
    // color: #1890ff;
  }

  span:nth-child(1) {
    border-radius: 6px 0 0 6px;
    border-right: none;

  }

  span:nth-child(2) {
    border-radius: 0;
    border-right: none;

  }

  span:nth-child(3) {
    border-radius: 0;
    border-right: none;

  }

  span:nth-child(4) {
    border-radius: 0 6px 6px 0;
    position: relative;
  }

  span:nth-child(4) .el-date-editor {
    position: absolute; //绝对定位
    top: 0;
    left: 0;
    opacity: 0; //设置完全透明
  }
}

.isActive {
  background: #1890ff;
  color: #ffffff !important;
  border: 1px solid #1890ff !important;
}

.ant-card-body {
  padding: 5px;
}

</style>
